import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"

export default function sobre() {
  return (
    <Layout color="white" breadcrumbs={["Sobre"]} item="1">
        <section class="banner_area">
        <div class="banner_inner d-flex align-items-center">
            <div class="overlay"  style={{backgroundImage:"url(/img/sobre.jpg)",backgroundPosition:"bottom",backgroundSize:"cover"}}></div>
            <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-9">
                <div class="banner_content text-center">
                    <h2>Sobre nós</h2>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section class="course_details_area section_gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 course_details_left">
                        <h2>Nossa história começou a mais de 20 anos em Guarapuava</h2>
                        <blockquote>
                            <p>
                            <strong>&#8220;Formar profissionais comprometidos com a excelência na sua área de atuação, com uma postura ética, contribuindo para sua inserção no contexto social como agente transformador.&#8221;</strong>
                            </p>
                        </blockquote>
                            <p>A VitalNet é uma empresa guarapuavana fundada no ano de 2000 por profissionais da área de informática com mais de trinta anos de experiência. Hoje toda essa experiência tornou a VitalNet em uma das empresas mais bem sucedidas no ramo de informática e consultoria da região, liderando o ramo de informática e desempenhando papel fundamental na formação de milhares de alunos.<br/>
Atualmente a VitalNet está consolidada como pioneira na área de informática e precurssora na região do sistema de educação à distância, em parceria com a Universidade Paulista.
                            </p>
			            <blockquote>
                            <p>
“Compromissos com a comunidade em que está inserida, por meio de ações educacionais, desenvolvendo com seus estudantes o desempenho crítico e eficaz da cidadania, formando cidadãos responsáveis, capazes de exercer a liderança de grupos sociais dos quais participem, priorizando soluções éticas, criativas e democráticas capazes de superar os problemas com os quais venham a se defrontar.”
                            </p>
                        </blockquote>

	                	</div>
                </div>
            </div>
        </section>
    </Layout>
  );
}